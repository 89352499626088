"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.forceComponentsUpdate = exports.localize = exports.translate = exports.setHandleMissingTranslation = exports.setTranslationsGetter = exports.setLocaleGetter = exports.setTranslations = exports.getTranslations = exports.addLocales = exports.addLocale = exports.setLocale = exports.getLocale = exports.settings = void 0;

var _parse = _interopRequireDefault(require("date-fns/parse"));

var _format = _interopRequireDefault(require("date-fns/format"));

var _formatDistanceToNowStrict = _interopRequireDefault(require("date-fns/formatDistanceToNowStrict"));

var _Base = _interopRequireDefault(require("./Base"));

var _utils = require("./utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var settings = {
  availableLocales: {},
  localeKey: 'en',
  translationsObject: {},
  getTranslations: null,
  getLocale: null,
  handleMissingTranslation: function handleMissingTranslation(text) {
    return text.split('.').pop();
  },

  get translations() {
    return this.getTranslations ? this.getTranslations() : this.translationsObject;
  },

  set translations(translations) {
    this.translationsObject = translations;
  },

  get locale() {
    return this.getLocale ? this.getLocale() : this.localeKey;
  },

  getLocaleObject: function getLocaleObject(locale) {
    var l = locale || this.locale;
    return this.availableLocales[l] || this.availableLocales[l.split('-')[0]];
  },

  set locale(locale) {
    this.localeKey = locale;
  }

};
exports.settings = settings;

var getLocale = function getLocale() {
  return settings.locale;
};

exports.getLocale = getLocale;

var setLocale = function setLocale(locale) {
  var rerenderComponents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  settings.locale = locale;
  settings.getLocale = null;

  if (rerenderComponents) {
    _Base.default.rerenderAll();
  }
};

exports.setLocale = setLocale;

var addLocale = function addLocale(name, locale) {
  settings.availableLocales[name] = locale;
};

exports.addLocale = addLocale;

var addLocales = function addLocales(locales) {
  settings.availableLocales = _objectSpread(_objectSpread({}, settings.availableLocales), locales);
};

exports.addLocales = addLocales;

var getTranslations = function getTranslations() {
  return settings.translations;
};

exports.getTranslations = getTranslations;

var setTranslations = function setTranslations(translations) {
  var rerenderComponents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  settings.translations = translations;
  settings.getTranslations = null;

  if (rerenderComponents) {
    _Base.default.rerenderAll();
  }
};

exports.setTranslations = setTranslations;

var setLocaleGetter = function setLocaleGetter(fn) {
  if (typeof fn !== 'function') {
    throw new Error('Locale getter must be a function');
  }

  settings.getLocale = fn;
};

exports.setLocaleGetter = setLocaleGetter;

var setTranslationsGetter = function setTranslationsGetter(fn) {
  if (typeof fn !== 'function') {
    throw new Error('Translations getter must be a function');
  }

  settings.getTranslations = fn;
};

exports.setTranslationsGetter = setTranslationsGetter;

var setHandleMissingTranslation = function setHandleMissingTranslation(fn) {
  if (typeof fn !== 'function') {
    throw new Error('Handle missing translation must be a function');
  }

  settings.handleMissingTranslation = fn;
};

exports.setHandleMissingTranslation = setHandleMissingTranslation;

var translate = function translate(key) {
  var replacements = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var locale = options.locale || settings.locale;
  var translation = '';

  try {
    var translationLocale = settings.translations[locale] ? locale : locale.split('-')[0];
    translation = (0, _utils.fetchTranslation)(settings.translations, "".concat(translationLocale, ".").concat(key), replacements.count);
  } catch (err) {
    if (options.returnNullOnError) return null;
    if (options.returnKeyOnError) return key;
    return settings.handleMissingTranslation(key, replacements);
  }

  return (0, _utils.replace)(translation, replacements);
};

exports.translate = translate;

var localize = function localize(value) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var locale = options.locale || settings.locale;

  if (options.dateFormat) {
    try {
      var localeObject = settings.getLocaleObject(locale);
      if (!localeObject) throw new Error("Locale ".concat(locale, " not added"));
      var parsedDate = options.parseFormat ? (0, _parse.default)(value, translate(options.parseFormat, {}, {
        locale: locale,
        returnKeyOnError: true
      }), new Date(), {
        locale: localeObject
      }) : new Date(value);

      if (options.dateFormat === 'distance-to-now') {
        return (0, _formatDistanceToNowStrict.default)(parsedDate, {
          addSuffix: true,
          locale: localeObject
        });
      }

      return (0, _format.default)(parsedDate, translate(options.dateFormat, {}, {
        locale: locale,
        returnKeyOnError: true
      }), {
        locale: localeObject
      });
    } catch (e) {
      return e.message;
    }
  }

  if (typeof value === 'number') {
    try {
      return new Intl.NumberFormat(locale, options).format(value);
    } catch (e) {
      return e.message;
    }
  }

  return value;
};

exports.localize = localize;

var forceComponentsUpdate = function forceComponentsUpdate() {
  _Base.default.rerenderAll();
};

exports.forceComponentsUpdate = forceComponentsUpdate;